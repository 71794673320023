import React from 'react';
export const ShieldCheckIcon = ({ className = '', fill = 'white' }) => {
  return (
    <svg
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.91112 15.9917C6.97037 16.0028 7.02963 16.0028 7.08888 15.9917C7.08888 15.9917 9.54521 15.5724 11.506 14.1047C12.8742 13.0839 14 11.5665 14 9.37598V3.84723C14 2.95888 13.4452 2.16984 12.621 1.88844C10.8003 1.26494 7.16968 0.028968 7.16968 0.028968C7.05656 -0.009656 6.93805 -0.009656 6.83032 0.028968C6.83032 0.028968 3.19431 1.27045 1.37899 1.88844C0.554829 2.16984 0 2.95888 0 3.84723V9.37598C0 11.5665 1.12582 13.0839 2.49404 14.1047C4.46018 15.5724 6.91112 15.9917 6.91112 15.9917ZM6.99731 2.75472C4.32012 2.75472 2.14929 4.97836 2.14929 7.72067C2.14929 10.463 4.32012 12.6866 6.99731 12.6866C9.67449 12.6866 11.8453 10.463 11.8453 7.72067C11.8453 4.97836 9.67449 2.75472 6.99731 2.75472ZM6.99731 3.85826C9.07657 3.85826 10.768 5.59083 10.768 7.72067C10.768 9.8505 9.07657 11.5831 6.99731 11.5831C4.91805 11.5831 3.22663 9.8505 3.22663 7.72067C3.22663 5.59083 4.91805 3.85826 6.99731 3.85826ZM4.47634 8.51522L6.09234 10.0491C6.20546 10.154 6.3509 10.2092 6.50173 10.1926C6.65256 10.1816 6.79261 10.1043 6.88419 9.97741L9.57753 6.3909C9.76068 6.14812 9.71758 5.8005 9.48057 5.61842C9.24356 5.43081 8.90419 5.47495 8.72643 5.71773L6.394 8.82421L5.20893 7.69859C4.99346 7.48892 4.64871 7.50548 4.4494 7.72618C4.24471 7.94689 4.26087 8.30003 4.47634 8.50418V8.51522Z"
        fill={fill}
      />
    </svg>
  );
};
