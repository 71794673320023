import { useRouter } from 'next/router';
import { FC, ReactElement } from 'react';
import { useAppSelector } from '../../../lib/hooks';

type propTypes = {
  IsHide: boolean;
  IsNavigateExternal: boolean;
  IsNewTab: boolean;
  Menu: string;
  Priority: number;
  URL: string;
  cleverName?: string;
  tabIndex?: number;
};

const Menu: FC<propTypes> = ({
  IsHide,
  Menu,
  URL,
  cleverName = '',
  tabIndex = 0,
}): ReactElement => {
  const router = useRouter();
  const cleverTap = useAppSelector(({ global }) => global.clevertap);
  return IsHide ? (
    <></>
  ) : (
    <div
      onClick={() => {
        cleverTap.event.push(cleverName);
        router.push(URL);
      }}
      tabIndex={tabIndex}
      className="cursor-pointer inline-flex text-15 items-center rounded-md text-gray hover:text-gray-900"
    >
      {Menu}
    </div>
  );
};

export default Menu;
