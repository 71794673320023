import {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { ExpandFilledArrowIcon } from '../../icons';

export type HeaderDropdownMenuService = {
  /**
   * This Property is use to set label to dropdown
   */
  closeDropdown: () => void;
};

type HeaderDropdownMenuProps = {
  /**
   * This Property is use to set label to dropdown
   */
  label?: string | undefined;

  image?: string | undefined;

  /**
   * JSX Element/HTML content for custom options container. It is display whenever user clicked on dropdown button.
   */
  children: (obj: { close: () => void }) => JSX.Element;

  setLabelButton?: () => JSX.Element;
  className?: string;
  ariaLabel: string;
  tabIndex?: number;
};

/**
 * This component render a dropdown list and search textbox, which help user to filter items from the list and allow user to select any item from the list.
 * @returns JSX.Element
 */
const HeaderDropdownMenu = forwardRef<
  HeaderDropdownMenuService,
  HeaderDropdownMenuProps
>(
  (
    {
      label,
      image,
      children,
      setLabelButton,
      className,
      ariaLabel,
      tabIndex,
    }: HeaderDropdownMenuProps,
    ref: ForwardedRef<HeaderDropdownMenuService>
  ) => {
    useImperativeHandle(ref, () => ({
      closeDropdown: () => {
        setIsDropdownOpen(false);
      },
    }));

    const dropdownRef = useRef<HTMLDivElement>(null);

    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

    useEffect(() => {
      function handleClickOutside(event: any) {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target)
        ) {
          setIsDropdownOpen(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [dropdownRef]);

    /**
     * This method is use to close the dropdown menu.
     */
    const close = (): void => {
      setIsDropdownOpen(false);
    };

    return (
      <>
        <div className="header-dropdown mobile-scroll w-full">
          <div className="relative min-w-40" ref={dropdownRef}>
            <button
              className={`flex items-center justify-between h-8 gap-[0.3125rem] w-full border-none ${
                className ? className : ''
              }`}
              id="profileid"
              onClick={() => setIsDropdownOpen((state) => !state)}
              aria-label={ariaLabel}
              tabIndex={tabIndex}
            >
              {setLabelButton ? (
                <>{setLabelButton()}</>
              ) : (
                <>
                  {image && (
                    <picture>
                      <img src={image} alt="icon" className="my-1" />
                    </picture>
                  )}
                  {label && (
                    <span className="uppercase rtl:pt-[0.25rem]">{`${label}`}</span>
                  )}
                  <ExpandFilledArrowIcon className="w-3 text-white" />
                </>
              )}
            </button>

            {isDropdownOpen && (
              <div className="menu absolute flex flex-col min-w-[169px] mt-3 shadow rounded-[16px] bg-white z-10 w-auto ltr:right-0 rtl:left-0">
                <div className="relative my-[10px]">{children({ close })}</div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
);

HeaderDropdownMenu.displayName = 'HeaderDropdownMenu';

export default HeaderDropdownMenu;
