import { ConfigurationKey, Locales } from '../../types/enums';
import {
  AllConfigurationResponse,
  CMSConfigurationResponse,
  ConfigurationResponse,
} from '../../types/models';
import { axiosInstance } from '../api';
import { axiosCmsInstance, axiosNodeInstance } from '../api/interceptors';
import { CommonUtils } from '../utilities';

export default class ConfigurationService {
  /**
   * This service is use to fetch all configuration details based on filter parameters.
   * @param {ConfigurationKey} configurationKey value of configuration key
   * @param {string} [currentLocale="en"] - i18n locale. `@default - "en"`
   * @returns {Promise<ConfigurationResponse>} Returns configuration result.
   */
  static fetchConfigurationValue = async (
    configurationKey: ConfigurationKey,
    currentLocale: string = Locales.EN
  ): Promise<ConfigurationResponse> => {
    try {
      const response = await axiosInstance.get<ConfigurationResponse>(
        `/api/configuration/configurationdata?configurationKey=${configurationKey}&languageId=${CommonUtils.getLanguageId(
          currentLocale
        )}`
      );
      return response.data;
    } catch (error) {
      console.error(`Error in GlobalService:fetchConfigurationValue`, error);
      throw error;
    }
  };

  /**
   * This service return cms configuration values based on paramters
   * @param {string} pagekey value of pagekey
   * @param {string} configurationkey value of configurationkey
   * @param {string} [currentLocale="en"] - i18n locale. `@default - "en"`
   * @returns {Promise<Array<CMSConfigurationResponse>>} Returns CMSconfiguration result.
   */
  static fetchCMSConfigurationValue = async (
    pagekey: string | null,
    configurationkey: string | null,
    currentLocale: string = Locales.EN
  ): Promise<Array<CMSConfigurationResponse>> => {
    const response = await axiosInstance.get<Array<CMSConfigurationResponse>>(
      `/api/configuration/getCMSConfiguration?${
        pagekey ? `pagekey=${pagekey}&` : ''
      }${
        configurationkey ? `configurationkey=${configurationkey}&` : ''
      }languageId=${CommonUtils.getLanguageId(currentLocale)}`
    );
    return response.data;
  };

  /**
   * This service return cms configuration values based on paramters
   * @param {string} pagekey value of pagekey
   * @param {string} configurationkey value of configurationkey
   * @param {string} [currentLocale="en"] - i18n locale. `@default - "en"`
   * @returns {Promise<{ [x: string]: string }>} Returns CMSconfiguration result.
   */
  static fetchCMSCLabelConstant = async (
    pagekey: string | null,
    configurationkey: string | null,
    currentLocale: string = Locales.EN
  ): Promise<{ [x: string]: string }> => {
    const response = await axiosInstance.get<Array<CMSConfigurationResponse>>(
      `/api/configuration/getCMSConfiguration?${
        pagekey ? `pagekey=${pagekey}&` : ''
      }${
        configurationkey ? `configurationkey=${configurationkey}&` : ''
      }languageId=${CommonUtils.getLanguageId(currentLocale)}`
    );

    let data: { [x: string]: string } = {};
    data = (response.data || []).reduce((x: any, y) => {
      x[y.ConfigurationKey] = y.ConfigurationValue;
      return x;
    }, {});
    return data;
  };

  /**
   * This service is use to fetch all configuration details.
   * @param {string} [currentLocale="en"] - i18n locale. `@default - "en"`
   * @returns {Promise<AllConfigurationResponse>} Returns configuration result.
   */
  static getConfigurationData = async (
    currentLocale: string = Locales.EN
  ): Promise<AllConfigurationResponse> => {
    try {
      const response = await axiosInstance.get<AllConfigurationResponse>(
        `/api/Configuration/allconfiguration?languageId=${CommonUtils.getLanguageId(
          currentLocale
        )}`
      );
      return response.data;
    } catch (error) {
      console.error(`Error in GlobalService:allconfiguration`, error);
      throw error;
    }
  };

  static getBannersData = async (
    currentLocale: string = Locales.EN,
    type: string
  ): Promise<AllConfigurationResponse> => {
    try {
      const response = await axiosNodeInstance.get<any>(
        `/strapi/banner?type=${type}&locale=${currentLocale}`
      );
      return (response && response.data && response.data.data) || [];
    } catch (error) {
      console.error(`Error in GlobalService:allconfiguration`, error);
      throw error;
    }
  };

  static getDealsLink = async (
    currentLocale: string = Locales.EN
  ): Promise<AllConfigurationResponse> => {
    try {
      const response = await axiosNodeInstance.get<any>(
        `/strapi/deals-link?locale=${currentLocale}`
      );

      return (response && response.data && response.data.data) || [];
    } catch (error) {
      console.error(`Error in GlobalService:getDealsLink`, error);

      throw error;
    }
  };

  static getDealsCtaText = async (
    currentLocale: string = Locales.EN
  ): Promise<AllConfigurationResponse> => {
    try {
      const response = await axiosNodeInstance.get<any>(
        `/strapi/deal-cta?locale=${currentLocale}`
      );

      return (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.data
      );
    } catch (error) {
      console.error(`Error in GlobalService:getDealsCtaText`, error);

      throw error;
    }
  };

  static getTranslations = async (
    currentLocale: string = Locales.EN
  ): Promise<AllConfigurationResponse> => {
    try {
      const response = await axiosNodeInstance.get<any>(
        `/strapi/translations?locale=${currentLocale}`
      );
      return (
        (response &&
          response.data &&
          response.data.data &&
          response.data.data.data &&
          response.data.data.data.attributes &&
          response.data.data.data.attributes.TextContent) ||
        null
      );
    } catch (error) {
      console.error(`Error in GlobalService:getTranslations`, error);
      throw error;
    }
  };

  static getEMIConfiguration = async (
    IsNew: boolean = false
  ): Promise<AllConfigurationResponse> => {
    try {
      const response = await axiosNodeInstance.get<any>(
        `/strapi/emi-configurations?populate=*&filters[IsActive][$eq]=true&filters[IsNew][$eq]=${IsNew}`
      );
      return (
        (response &&
          response.data &&
          response.data.data &&
          response.data.data.data &&
          response.data.data.data.length &&
          response.data.data.data[0]) ||
        null
      );
    } catch (error) {
      console.error(`Error in GlobalService:getTranslations`, error);
      throw error;
    }
  };

  static getSeoTag = async (
    currentLocale: string = Locales.EN
  ): Promise<AllConfigurationResponse> => {
    try {
      const response = await axiosNodeInstance.get<any>(
        `/strapi/seo-tags?locale=${currentLocale}`
      );
      return (
        (response &&
          response.data &&
          response.data.data &&
          response.data.data.data) ||
        []
      );
    } catch (error) {
      console.error(`Error in GlobalService:getSeoTag`, error);
      throw error;
    }
  };
}
