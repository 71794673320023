import React, {
  createContext,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import ConfigurationService from '../helpers/services/configuration.service';

const Context = createContext<{
  LabelConstants: any;
  setLabelConstants: (value: SetStateAction<any>) => void;
}>({
  LabelConstants: {},
  setLabelConstants(value) {},
});

export function TranslationProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const router = useRouter();
  const [LabelConstants, setLabelConstants] = useState<any>({});

  useEffect(() => {
    const initialLoad = async () => {
      const translations = await ConfigurationService.getTranslations(
        router.locale
      );
      setLabelConstants(translations);
    };
    initialLoad();
  }, [router.locale]);

  return (
    <Context.Provider
      value={{
        LabelConstants,
        setLabelConstants,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function useTranslationContext() {
  return useContext(Context);
}
